var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-client panel is-primary"},[_c('h1',{staticClass:"title is-6 panel-heading mb-0"},[_vm._v(" Редактирование клиента (юридическое лицо) ")]),_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"ИНН","type":{
            'is-danger': _vm.$v.inn.$error || 'inn' in _vm.serverValid,
            'is-success': !_vm.$v.inn.$invalid && _vm.$v.inn.$dirty,
          },"message":{ 'Некорректно указан ИНН': _vm.$v.inn.$error }}},[_c('b-autocomplete',{attrs:{"placeholder":"ИНН","field":"data.inn","data":_vm.innData,"keep-first":false,"open-on-focus":false,"clearable":true,"loading":_vm.isFetching},on:{"typing":function($event){return _vm.getDadata($event, 'inn')},"select":_vm.setOrgName,"blur":function($event){_vm.onTouchField('inn');
              _vm.removeKeyFromServerValid('inn');}},model:{value:(_vm.inn),callback:function ($$v) {_vm.inn=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"inn"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Наименование организации","type":{
            'is-danger': _vm.$v.orgName.$error || 'orgName' in _vm.serverValid,
            'is-success': !_vm.$v.orgName.$invalid && _vm.$v.orgName.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.orgName.$error }}},[_c('b-input',{attrs:{"placeholder":"Наименование организации"},on:{"blur":function($event){_vm.onTouchField('orgName');
              _vm.removeKeyFromServerValid('orgName');}},model:{value:(_vm.orgName),callback:function ($$v) {_vm.orgName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"orgName"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Телефон","type":{
            'is-danger': _vm.$v.phone.$error || 'phone' in _vm.serverValid,
            'is-success': !_vm.$v.phone.$invalid && _vm.$v.phone.$dirty,
          },"message":{ 'Телефон в формате +7(900)000-00-00': _vm.$v.phone.$error }}},[_c('IMaskComponent',{staticClass:"input",class:{
              'is-danger': _vm.$v.phone.$error || 'phone' in _vm.serverValid,
              'is-success': !_vm.$v.phone.$invalid && _vm.$v.phone.$dirty,
            },attrs:{"placeholder":"+7(900)000-00-00","maxlength":"16","inputmode":"numeric","mask":'+{7}({9}00)000-00-00'},on:{"blur":function($event){_vm.onTouchField('phone');
              _vm.removeKeyFromServerValid('phone');}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Email","type":{
            'is-danger': _vm.$v.email.$error || 'email' in _vm.serverValid,
            'is-success': !_vm.$v.email.$invalid && _vm.$v.email.$dirty,
          },"message":{ 'Некорректно указан email': _vm.$v.email.$error }}},[_c('b-input',{attrs:{"placeholder":"example@mail.com"},on:{"blur":function($event){_vm.onTouchField('email');
              _vm.removeKeyFromServerValid('email');}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1)],1)]),_c('div',{staticClass:"button-wrapper"},[_c('b-button',{attrs:{"type":"is-info","native-type":"submit"}},[_vm._v("Обновить")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }