<template>
  <div class="new-client panel is-primary">
    <h1 class="title is-6 panel-heading mb-0">
      Редактирование клиента (юридическое лицо)
    </h1>
    <form class="box" @submit.prevent="submit">
      <div class="columns is-multiline">
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="ИНН"
            :type="{
              'is-danger': $v.inn.$error || 'inn' in serverValid,
              'is-success': !$v.inn.$invalid && $v.inn.$dirty,
            }"
            :message="{ 'Некорректно указан ИНН': $v.inn.$error }"
          >
            <b-autocomplete
              placeholder="ИНН"
              field="data.inn"
              :data="innData"
              :keep-first="false"
              :open-on-focus="false"
              :clearable="true"
              :loading="isFetching"
              v-model.trim="inn"
              @typing="getDadata($event, 'inn')"
              @select="setOrgName"
              @blur="
                onTouchField('inn');
                removeKeyFromServerValid('inn');
              "
            >
            </b-autocomplete>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Наименование организации"
            :type="{
              'is-danger': $v.orgName.$error || 'orgName' in serverValid,
              'is-success': !$v.orgName.$invalid && $v.orgName.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.orgName.$error }"
          >
            <b-input
              placeholder="Наименование организации"
              v-model.trim="orgName"
              @blur="
                onTouchField('orgName');
                removeKeyFromServerValid('orgName');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Телефон"
            :type="{
              'is-danger': $v.phone.$error || 'phone' in serverValid,
              'is-success': !$v.phone.$invalid && $v.phone.$dirty,
            }"
            :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
          >
            <IMaskComponent
              placeholder="+7(900)000-00-00"
              maxlength="16"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.phone.$error || 'phone' in serverValid,
                'is-success': !$v.phone.$invalid && $v.phone.$dirty,
              }"
              :mask="'+{7}({9}00)000-00-00'"
              v-model.trim="phone"
              @blur="
                onTouchField('phone');
                removeKeyFromServerValid('phone');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Email"
            :type="{
              'is-danger': $v.email.$error || 'email' in serverValid,
              'is-success': !$v.email.$invalid && $v.email.$dirty,
            }"
            :message="{ 'Некорректно указан email': $v.email.$error }"
          >
            <b-input
              placeholder="example@mail.com"
              v-model.trim="email"
              @blur="
                onTouchField('email');
                removeKeyFromServerValid('email');
              "
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button type="is-info" native-type="submit">Обновить</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import {
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators';
import DadataAPI from '@/api/dadata';
import debounce from '@/utils/debounce';
import { onTouchField, removeKeyFromServerValid } from '@/utils/helpers';

export default {
  name: 'UpdateClientEntity',
  data() {
    return {
      innData: [],
      isFetching: false,
      phone: '',
      email: '',
      inn: '',
      orgName: '',
      onTouchField,
      removeKeyFromServerValid,
      serverValid: {},
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    await this.$store.dispatch('client/getClient', this.$route.params.id);
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    noValid() {
      return this.$store.state.client.noValid;
    },
    clientData() {
      return this.$store.state.client.clientData;
    },
  },
  watch: {
    noValid(fields) {
      this.serverValid = { ...fields };
    },
    clientData(data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this[key] = data[key];
        }
      }
    },
  },
  methods: {
    setOrgName(option) {
      if (option !== null) {
        this.orgName = option.value;
      }
    },
    getDadata: debounce(async function (value, field) {
      if (!value.length) {
        this[field + 'Data'] = [];
        return;
      }

      this.isFetching = true;

      let data = await DadataAPI.getINN({
        value,
      });

      this[field + 'Data'] = [];

      if (data.length) {
        data.forEach((item) => this[field + 'Data'].push(Object.freeze(item)));
      }

      this.isFetching = false;
    }, 500),
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        phone: this.phone,
        email: this.email,
        inn: this.inn,
        orgName: this.orgName,
        id: this.clientData.id,
      };

      this.$store.dispatch('client/updateClient', clientData);

      // if (success) {
      //   setTimeout(() => {
      //     window.top.location.href = '/workspace';
      //   }, 2000);
      // }
    },
  },
  validations: {
    inn: {
      maxLength: maxLength(15),
      required,
    },
    phone: {
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    email: {
      email,
      maxLength: maxLength(40),
    },
    orgName: {
      minLength: minLength(1),
      maxLength: maxLength(60),
      // required,
    },
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss">
.new-client {
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;
    }
  }
}
</style>
